const PrivateRouteConst = {
  INPUT_FILE_UPLOAD: 'uploadInputDataFile',
  MASTER_FILE_UPLOAD: 'uploadmasterDataFile',
  START_PAYOUT: 'startPayout',
  RUN_SIMULATION: 'runSimulationView',
  VIEW_SIMULATION: 'allViewSimulationView',
  CONFIGURE_SIMULATION: 'AllConfigureSimulationView',
  UNIFICATION_HISTORY: 'viewUnificationHistory',
  ACCOUNTING: 'accounting',
  INPUT_FILE_DOWNLOAD: 'downloadInputDataFile',
  MASTER_FILE_DOWNLOAD: 'downloadmasterDataFile',
  MY_TASK: 'TaskView',
  PAYOUT_HISTORY: 'viewPayoutHistory',
  UNIFICATION_VIEW: 'viewUnificationHistory',
  CHART_MAPPING_VIEW: 'chartMappingView',
  CHART_VIEW: 'chartView',
};
export default PrivateRouteConst;
