// Package Imports
import { EyeFilled } from '@ant-design/icons';

// Project Imports
import PrivateRouteConst from 'core/common/privateRouteConstant';
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const ViewSimulationResource: ResourceItem = {
  name: resourceName.viewSimulation,
  identifier: PrivateRouteConst.VIEW_SIMULATION,
  list: `${resourceName.viewSimulation}`,
  show: `${resourceName.viewSimulation}/show/:id`,
  meta: {
    label: '',
    icon: <EyeFilled />,
    parent: resourceName.simulation,
  },
};

export default ViewSimulationResource;
