const dashboard = 'dashboard';
const upload = 'upload';
const incentiveProgram = 'incentive_program';
const organization = 'organization';
const fileUploadBaseUrl = `${process.env.REACT_APP_API_URL}/file_upload`;
const fileUpload = 'file_upload';
const downloadFileBaseUrl = `${process.env.REACT_APP_API_URL_MASTER}/download_file`;
const downloadUnifiedFile = `${process.env.REACT_APP_API_URL_UNIFIED}/download_unified_data_file`;
const userEvalPermissionDetails = 'user_eval_permission_details';
const frequency = 'frequency';
const workflow = 'workflow';
const program = 'program_configuration';
const updateUserLanguage = 'update_user_language';
const programConfigDetails = 'program_config_details';
const inputSourceConnection = 'input_source_connections';
const payoutInitiationStatus = 'unification_run_status';
const inputFileDownload = `${process.env.REACT_APP_API_URL_INPUT_SOURCE}/download_data`;
const masterFileDownload = `${process.env.REACT_APP_API_URL_INPUT_SOURCE}/download_data`;
const unificationDownload = `${process.env.REACT_APP_API_URL_INPUT_SOURCE}/download_data`;
const paymentManagement = `${process.env.REACT_APP_API_PAYOUT_URL}`;
const startCalculation = 'start_calculation';
const getTaskList = 'get_task_list';
const myTask = 'my_task';
const getTaskInstance = 'get_task_instance';
const getWorkflowWiseUnificationStatus = 'get_workflow_wise_unification_status';
const paymentProcess = 'payment_process';
const deletePaymentProcess = 'delete_payment_process';
const userRoles = 'get_user_roles_program_details';
const updateUserRoleProgramDetails = 'update_user_role_program_details';
const unificationRunStatus = 'unification_run_status';
const unificationHistoryTree = 'get_unified_making_structure';
const payoutLedger = 'get_payout_ledger_list';
const amounthWithdrawal = 'withdraw_payout_amount';
const dataSourceColumnList = 'data_source_column_list';
const graphDetails = 'graph_details';
const getIprogramWiseCalculationsStatus =
  'get_iprogram_wise_calculations_status';
const programWorkflowDetails = 'program_workflow_details';
const getCollectionPayoutDetails = 'get_collection_payout_details';
const getPaymentPartyUserCounts = 'get_payment_party_user_counts';
const getRuleDetailsCount = 'get_rule_details_count';
const getDataSyncDetails = 'get_data_sync_details';
const getMinimumMaximumPayoutDetails = 'get_minimum_maximum_payout_details';
const getPayoutApprovalTaskList = 'get_payout_approval_task_list';
const bulkUpdateTask = 'bulk_update_task';
const getTaskType = 'get_task_type';
const getPayoutReviewDetails = 'get_payout_review_details';
const getPayoutReviewCountDetails = 'get_payout_review_count_details';
const getReportingPayoutList = 'get_reporting_payout_variable_details';
const getProgramSimulationDetails = 'get_program_simulation_details';
const simulationMappingDetails = 'simulation_configuration_mapping_details';
const saveSimulationDetails = 'save_simulation_details';
const saveSimulationTaskConfig = 'simulation_task_configuration';
const getSimulationTaskList = 'simulated_task_list';
const getSimulationSummaryDetails = 'simulated_task_summary';
const getElasticSearchFields = 'get_elastic_search_fields';
const simulatedTaskDetails = 'simulated_task_details';
const getSimulationCalcDetails = 'simulation_calculation_details';

const apiEndpoint = {
  dashboard,
  upload,
  incentiveProgram,
  organization,
  fileUploadBaseUrl,
  downloadFileBaseUrl,
  userEvalPermissionDetails,
  frequency,
  workflow,
  program,
  updateUserLanguage,
  programConfigDetails,
  inputSourceConnection,
  payoutInitiationStatus,
  fileUpload,
  downloadUnifiedFile,
  inputFileDownload,
  masterFileDownload,
  paymentManagement,
  startCalculation,
  getTaskList,
  myTask,
  getTaskInstance,
  getWorkflowWiseUnificationStatus,
  paymentProcess,
  deletePaymentProcess,
  userRoles,
  updateUserRoleProgramDetails,
  unificationRunStatus,
  unificationHistoryTree,
  unificationDownload,
  payoutLedger,
  amounthWithdrawal,
  dataSourceColumnList,
  graphDetails,
  getIprogramWiseCalculationsStatus,
  programWorkflowDetails,
  getCollectionPayoutDetails,
  getPaymentPartyUserCounts,
  getRuleDetailsCount,
  getDataSyncDetails,
  getMinimumMaximumPayoutDetails,
  getPayoutApprovalTaskList,
  bulkUpdateTask,
  getTaskType,
  getPayoutReviewDetails,
  getPayoutReviewCountDetails,
  getReportingPayoutList,
  getProgramSimulationDetails,
  simulationMappingDetails,
  saveSimulationDetails,
  saveSimulationTaskConfig,
  getSimulationTaskList,
  getSimulationSummaryDetails,
  getElasticSearchFields,
  simulatedTaskDetails,
  getSimulationCalcDetails,
};

export default apiEndpoint;
