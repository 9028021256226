// Package Imports
import { RadarChartOutlined } from '@ant-design/icons';

// Project Imports
import PrivateRouteConst from 'core/common/privateRouteConstant';
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const RunSimulationResource: ResourceItem = {
  name: resourceName.runSimulation,
  identifier: PrivateRouteConst.RUN_SIMULATION,
  list: `${resourceName.runSimulation}`,
  meta: {
    label: '',
    icon: <RadarChartOutlined />,
    parent: resourceName.simulation,
  },
};

export default RunSimulationResource;
