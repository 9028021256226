// Package Imports
import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Authenticated } from '@refinedev/core';
import {
  CatchAllNavigate,
  NavigateToResource,
} from '@refinedev/react-router-v6';
import { ThemedLayoutV2 } from '@refinedev/antd';

// Project Imports
import DashboardRoute from 'core/routes/DashboardRoute';
import ErrorRoute from 'core/routes/ErrorRoute';
import LoginRoute from 'core/routes/LoginRoute';
import Header from 'components/header';
import routeName from 'core/common/routeName';

import CustomSider from 'components/sider/sider';
import PayoutInitializationRoute from 'core/routes/PayoutInitializationRoute';
import InputFileUploadRoute from 'core/routes/InputFileUploadRoute';
import MasterUploadRoute from 'core/routes/MasterUploadRoute';
import InputFileDownloadRoute from 'core/routes/InputFileDownloadRoute';
import MasterDownloadRoute from 'core/routes/MasterDownloadRoute';
import MyTaskRoute from 'core/routes/MyTaskRoute';
import PayoutHistoryRoute from 'core/routes/PayoutHistoryRoute';
import UnificationHistoryRoute from 'core/routes/UnificationList';
import ProfileRoute from 'core/routes/ProfileRoute';
import AccountingRoute from 'core/routes/AccountingRoute';
import ChartMappingRoute from 'core/routes/ChartMappingRoute';
import ChartRoute from 'core/routes/ChartRoute';
import DashboardChartMappingRoute from 'core/routes/DashboardChartMappingRoute';
import OverviewRoute from 'core/routes/OverviewRoute';
import RunSimulationRoute from 'core/routes/RunSimulationRoute';
import ViewSimulationRoute from 'core/routes/ViewSimulationRoute';

const AppRoutes: React.FC = () => (
  <Routes>
    <Route
      element={
        <Authenticated fallback={<CatchAllNavigate to={routeName.login} />}>
          <ThemedLayoutV2
            Header={() => <Header />}
            Sider={() => <CustomSider />}
          >
            <Outlet />
          </ThemedLayoutV2>
        </Authenticated>
      }
    >
      <Route
        index
        element={<NavigateToResource resource={routeName.dashboard} />}
      />
      {DashboardRoute}
      {MyTaskRoute}
      {RunSimulationRoute}
      {ViewSimulationRoute}
      {ErrorRoute}
      {PayoutInitializationRoute}
      {InputFileUploadRoute}
      {MasterUploadRoute}
      {InputFileDownloadRoute}
      {MasterDownloadRoute}
      {UnificationHistoryRoute}
      {PayoutHistoryRoute}
      {AccountingRoute}
      {ProfileRoute}
      {ChartMappingRoute}
      {ChartRoute}
      {DashboardChartMappingRoute}
      {OverviewRoute}
    </Route>
    {ErrorRoute}
    <Route
      element={
        <Authenticated fallback={<Outlet />}>
          <NavigateToResource />
        </Authenticated>
      }
    >
      {LoginRoute}
    </Route>
  </Routes>
);

export default AppRoutes;
