const SocketEvent = {
  INITIATE_UNIFICATION_EVENT_NAME: 'Data Unification',

  INPUT_SYNC_EVENT_NAME: 'Input Data Upload',
  MASTER_SYNC_EVENT_NAME: 'Master Data Upload',

  START_CALCULATION_EVENT_NAME: 'Payout Calculation',
};

export default SocketEvent;
