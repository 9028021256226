// package imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// project imports
import routeName from 'core/common/routeName';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

const ProgramSimulation = lazy(
  () => import('pages/simulation/runSimulation/programSimulation')
);

const RunSimulationRoute = (
  <Route path={routeName.runSimulation}>
    <Route
      index
      element={
        <PrivateRoute name={PrivateRouteConst.RUN_SIMULATION}>
          <Suspense
            fallback={
              <div>
                <Loading />
              </div>
            }
          >
            <ProgramSimulation />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="edit/:id"
      element={
        <PrivateRoute name={[PrivateRouteConst.RUN_SIMULATION]}>
          <Suspense fallback={<Loading />}>
            <ProgramSimulation />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="show/:id"
      element={
        <PrivateRoute name={[PrivateRouteConst.RUN_SIMULATION]}>
          <Suspense fallback={<Loading />}>
            <ProgramSimulation />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default RunSimulationRoute;
