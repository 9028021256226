// Package Imports
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const SimulationResource: ResourceItem = {
  name: resourceName.simulation,
  identifier: ModuleIdentifierConst.SIMULATION,
  meta: {
    label: 'Simulation',
  },
};

export default SimulationResource;
