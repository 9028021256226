// Project Imports
import Augment8DashboardResource from 'core/resources/Augment8DashboardResource';
import PayoutInitializationResource from 'core/resources/PayoutInitialization';
import PayoutResource from 'core/resources/Payout';
import InputFileUploadResource from 'core/resources/InputFileUpload';
import FileManagement from 'core/resources/FileManagement';
import MasterFileUploadResource from 'core/resources/MasterFileUpload';
import Maintainence from 'core/resources/Maintainence';
import InputFileDownloadResource from 'core/resources/InputFileDownload';
import MasterFileDownloadResource from 'core/resources/masterFileDownload';
import MyTaskResource from 'core/resources/MyTask';
import UnificationHistoryResource from 'core/resources/UnificationList';
import PayoutHistoryResource from 'core/resources/PayoutHistory';
import AccountingResource from 'core/resources/Accounting';
import SimulationResource from 'core/resources/Simulation';
import RunSimulationResource from 'core/resources/RunSimulation';
import ViewSimulationResource from 'core/resources/ViewSimulation';
// import DashboardMappingResource from 'core/resources/DashboardMapping';
// import ChartMappingResource from 'core/resources/ChartMapping';
// import ChartResource from 'core/resources/Chart';
// import DashboardChartMappingResource from 'core/resources/DashboardChartMapping';

const resources = [
  Augment8DashboardResource,
  MyTaskResource,
  InputFileUploadResource,
  FileManagement,
  Augment8DashboardResource,
  MasterFileUploadResource,
  Maintainence,
  PayoutInitializationResource,
  PayoutResource,
  MasterFileDownloadResource,
  InputFileDownloadResource,
  UnificationHistoryResource,
  PayoutHistoryResource,
  AccountingResource,
  SimulationResource,
  RunSimulationResource,
  ViewSimulationResource,
  // DashboardMappingResource,
  // ChartMappingResource,
  // ChartResource,
  // DashboardChartMappingResource,
];

export default resources;
