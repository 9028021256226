const ModuleIdentifierConst = {
  FILE_MANAGEMENT: 'fileManagement',
  MAINTAINENCE: '"maintanance"',
  PAYOUT: 'payout',
  ACCOUNTING: 'accounting',
  DASHBOARD_MAPPING: 'dashboardMapping',
  SIMULATION: 'simulation',
};

export default ModuleIdentifierConst;
