// Package Imports
// import PrivateRouteConst from 'core/common/privateRouteConstant';
import routeName from 'core/common/routeName';
// import PrivateRoute from 'core/utils/PrivateRoute';
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import Loading from 'components/loading/loading';

// Project Imports
// const DashboardChartMapping = lazy(
//   () => import('pages/dashboardMapping/dashboardChartMapping')
// );

const Dashboard = lazy(() => import('pages/dashboard/dashboard'));
const DashboardChartMappingRoute = (
  <Route path={routeName.dashboard}>
    <Route
      index
      element={
        // <PrivateRoute name={[PrivateRouteConst.INPUT_FILE_DOWNLOAD]}>
        <Suspense
          fallback={
            <div>
              <Loading />
            </div>
          }
        >
          <Dashboard />
        </Suspense>
        // </PrivateRoute>
      }
    />
  </Route>
);

export default DashboardChartMappingRoute;
